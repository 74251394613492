import React, { createContext, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { login } from './Api/api';
const FormContext = createContext();

export const FormProvider = ({ children }) => {
   
 const [bill, setBill] = useState({});
 
  const Login = async (data) => {
    try {
     
      const res = await login(data);
      console.log(res);
      if (res?.data?.success) {
        toast.success(res?.data?.message)
        return true
      }
    } catch (error) {
      toast.error(error?.message);
      return false
    }
  }


  return (
    <FormContext.Provider value={{ Login, bill, setBill}}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  return useContext(FormContext);
};
