import React from "react";

const SidebarFooter = () => {
  return (
    <div className="siderbar_footer" style={{background: "#2A3042" }}>
      © {new Date().getFullYear()}{" "}
      <a href="https://ludobrothers.computerclasses.in" target="_blank" rel="noreferrer">
      https://ludobrothers.computerclasses.in
      </a>
    
    </div>
  );
};

export default SidebarFooter;
