import React from "react";
import ReactDOM from "react-dom/client";
import {FormProvider} from './ContextApi/FormContext'
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.min.css";
import "./app.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import  { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <FormProvider>
    <App />
    <Toaster  />
  </FormProvider>

);
reportWebVitals();
