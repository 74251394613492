import React, { useState } from 'react';
import { createDepartment } from '../../ContextApi/Api/api'
import toast from 'react-hot-toast';
function Department() {
  const [formData, setFormData] = useState({
    name: '',
    owner: '',
    mobile: ''
  });

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.name.trim() === "" ||
      formData.owner.trim() === "" ||
      formData.mobile.trim() === ""
    ) {
      // If any field is empty, show an error message
      toast.error("Please fill in all fields");
      return;
    }
    try {
      const res = await createDepartment(formData);

      if (res?.data?.success) {
        setFormData({
          name: '',
          owner: '',
          mobile: ''
        })
        toast.success(res?.data?.message)
      }

    } catch (error) {
      console.log(error);
      toast.error('Try again !')
    }

  };

  return (
    <div>
      <div className="card-body">
        <h4 className="card-title mb-4">Department</h4>

        <form>

          <div className="row">

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="departmentName">Department Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>


            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="departmentOwner">Department Owner</label>
                <input
                  type="text"
                  className="form-control"
                  id="owner"
                  value={formData.owner}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="mobileNumber">Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  id="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <button onClick={handleSubmit} className="btn btn-primary w-md">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Department;
