import React, { useEffect, useState } from 'react'
import { getfilterDepartment, deleteDepartment, updateDepartment } from '../../ContextApi/Api/api'
import { Modal, Button } from 'antd'
import toast from 'react-hot-toast';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
function ShowDepartment() {

  const [data, setData] = useState([])
  const [name, setName] = useState('')
  const [updt, setUpdate] = useState(false);
  const [updateData, setUpData] = useState({})
  const [id, setId] = useState('')
  const [lastpage, setLastPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);


  const fetch = async () => {
    try {
      const res = await getfilterDepartment({ name, page: currentPage });
      // toast.success(res?.data?.message)
      console.log(res?.data);
      if (res?.data?.data) {
        setCurrentPage(res?.data?.current_page)
        setLastPage(res?.data?.last_page)
        setData(res?.data?.data)
      } else {
        toast.error(res?.response?.data?.message)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }

  useEffect(() => {
    fetch()
  }, [name, currentPage])

  const handleCancel = () => {
    setUpdate(!updt)
  }

  const handleOK = async () => {
    try {

      const res = await updateDepartment({ ...updateData, id })
      console.log(res);
      if (res?.data?.data) {
        toast.success(res?.data?.message)
        setUpdate(!updt)
        fetch()
      } else {
        toast.error(res?.response?.data?.message)
      }

    } catch (error) {
      toast.error(error);
      console.log(error);
    }

  }


  const handelDelete = async (data) => {
    try {
      const res = await deleteDepartment(data);
      if (res?.data?.message) {
        toast.success(res?.data?.message);
        fetch()
      } else {
        toast.error(res?.response?.data?.message)
      }

    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  }


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };




  return (
    <div>
      <Modal title="Update Department" open={updt} onOk={handleOK} onCancel={handleCancel}>
        {/* name, owner, mobile */}
        <div style={{ display: 'flex', flexDirection: "column", gap: "10px" }}>
          <input type="text" className="form-control" name='name' onChange={e => setUpData((pre) => ({ ...pre, "name": e.target.value }))} placeholder="Department Name" />

          <input type="text" className="form-control" name='owner' onChange={e => setUpData((pre) => ({ ...pre, "owner": e.target.value }))} placeholder="Department Owner" />

          <input type="text" className="form-control" name='mobile' onChange={e => setUpData((pre) => ({ ...pre, "mobile": e.target.value }))} placeholder="Department Mobile" />

        </div>
      </Modal>
      <div className="card-body">
        <div className="row mb-2">
          <div className="col-sm-2">
            <div className="search-box mr-2 mb-2 d-inline-block">
              <div className="position-relative">
                <input type="text" className="form-control" onChange={e => setName(e.target.value)} placeholder="Department" />

              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Department Name</th>
                  <th>Department Owner Name</th>
                  <th>Mobile</th>
                </tr>
              </thead>
              <tbody>

                {
                  data.map((item, index) => (
                    <tr key={index}>
                      <td className="font-bold">{item.name}</td>
                      <td>{item.owner}</td>
                      <td>
                        {item.mobile} &nbsp; &nbsp;
                        <i className="fa-regular fa-trash-can hover:text-red-300" onClick={() => handelDelete(item?._id)}></i>
                        &nbsp; &nbsp;
                        <i onClick={() => { setUpdate(!updt); setId(item?._id) }} className="fa-solid fa-pen-to-square"></i>
                      </td>
                    </tr>
                  ))

                }

              </tbody>
            </table>
            <div className='p-2'>
              <Stack spacing={2}>
                <Pagination count={lastpage} color="secondary" size="small" onChange={handlePageChange} page={currentPage} />
              </Stack>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}

export default ShowDepartment