import React, { useEffect } from "react";

function Home() {

  useEffect(() => {
 
    return () => { };
  }, []);

  return (
    <div>

      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Dashboard</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="">Dashboards</a></li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>

            </div>
          </div>
        </div>


        <div className="row">

          <div className="col-xl-12">
            <div className="row">
              <div className="col-md-4">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="media">
                      <div className="media-body">
                        <p className="text-muted font-weight-medium">Orders</p>
                        <h4 className="mb-0">1,235</h4>
                      </div>

                      <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                        <span className="avatar-title">
                          <i className="bx bx-copy-alt font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="media">
                      <div className="media-body">
                        <p className="text-muted font-weight-medium">Revenue</p>
                        <h4 className="mb-0">$35, 723</h4>
                      </div>

                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-archive-in font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mini-stats-wid">
                  <div className="card-body">
                    <div className="media">
                      <div className="media-body">
                        <p className="text-muted font-weight-medium">Average Price</p>
                        <h4 className="mb-0">$16.2</h4>
                      </div>

                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="bx bx-purchase-tag-alt font-size-24"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>


        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title mb-4">Latest Transaction</h4>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: 20 }}>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1">&nbsp;</label>
                          </div>
                        </th>
                        <th>Order ID</th>
                        <th>Billing Name</th>
                        <th>Date</th>
                        <th>Total</th>
                        <th>Payment Status</th>
                        <th>Payment Method</th>
                        <th>View Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck2" />
                            <label className="custom-control-label" htmlFor="customCheck2">&nbsp;</label>
                          </div>
                        </td>
                        <td><a href="" className="text-body font-weight-bold">#SK2540</a> </td>
                        <td>Neal Matthews</td>
                        <td>
                          07 Oct, 2019
                        </td>
                        <td>
                          $400
                        </td>
                        <td>
                          <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                        </td>
                        <td>
                          <i className="fab fa-cc-mastercard mr-1"></i> Mastercard
                        </td>
                        <td>
                          <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" data-toggle="modal" data-target=".exampleModal">
                            View Details
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck3" />
                            <label className="custom-control-label" htmlFor="customCheck3">&nbsp;</label>
                          </div>
                        </td>
                        <td><a href="" className="text-body font-weight-bold">#SK2541</a> </td>
                        <td>Jamal Burnett</td>
                        <td>
                          07 Oct, 2019
                        </td>
                        <td>
                          $380
                        </td>
                        <td>
                          <span className="badge badge-pill badge-soft-danger font-size-12">Chargeback</span>
                        </td>
                        <td>
                          <i className="fab fa-cc-visa mr-1"></i> Visa
                        </td>
                        <td>

                          <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" data-toggle="modal" data-target=".exampleModal">
                            View Details
                          </button>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck4" />
                            <label className="custom-control-label" htmlFor="customCheck4">&nbsp;</label>
                          </div>
                        </td>
                        <td><a href="" className="text-body font-weight-bold">#SK2542</a> </td>
                        <td>Juan Mitchell</td>
                        <td>
                          06 Oct, 2019
                        </td>
                        <td>
                          $384
                        </td>
                        <td>
                          <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                        </td>
                        <td>
                          <i className="fab fa-cc-paypal mr-1"></i> Paypal
                        </td>
                        <td>

                          <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" data-toggle="modal" data-target=".exampleModal">
                            View Details
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck5" />
                            <label className="custom-control-label" htmlFor="customCheck5">&nbsp;</label>
                          </div>
                        </td>
                        <td><a href="" className="text-body font-weight-bold">#SK2543</a> </td>
                        <td>Barry Dick</td>
                        <td>
                          05 Oct, 2019
                        </td>
                        <td>
                          $412
                        </td>
                        <td>
                          <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                        </td>
                        <td>
                          <i className="fab fa-cc-mastercard mr-1"></i> Mastercard
                        </td>
                        <td>
                          <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" data-toggle="modal" data-target=".exampleModal">
                            View Details
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck6" />
                            <label className="custom-control-label" htmlFor="customCheck6">&nbsp;</label>
                          </div>
                        </td>
                        <td><a href="" className="text-body font-weight-bold">#SK2544</a> </td>
                        <td>Ronald Taylor</td>
                        <td>
                          04 Oct, 2019
                        </td>
                        <td>
                          $404
                        </td>
                        <td>
                          <span className="badge badge-pill badge-soft-warning font-size-12">Refund</span>
                        </td>
                        <td>
                          <i className="fab fa-cc-visa mr-1"></i> Visa
                        </td>
                        <td>

                          <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" data-toggle="modal" data-target=".exampleModal">
                            View Details
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck7" />
                            <label className="custom-control-label" htmlFor="customCheck7">&nbsp;</label>
                          </div>
                        </td>
                        <td><a href="" className="text-body font-weight-bold">#SK2545</a> </td>
                        <td>Jacob Hunter</td>
                        <td>
                          04 Oct, 2019
                        </td>
                        <td>
                          $392
                        </td>
                        <td>
                          <span className="badge badge-pill badge-soft-success font-size-12">Paid</span>
                        </td>
                        <td>
                          <i className="fab fa-cc-paypal mr-1"></i> Paypal
                        </td>
                        <td>

                          <button type="button" className="btn btn-primary btn-sm btn-rounded waves-effect waves-light" data-toggle="modal" data-target=".exampleModal">
                            View Details
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default Home;
