import React, { useEffect, useState } from 'react'
import { getDepartment, getWorkType, getfilterLabourWorktype, putAttendence } from '../../ContextApi/Api/api';
import toast from 'react-hot-toast';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);



const LabourAttendence = () => {

    const [department, setDepart] = useState([])
    const [worktype, setWorkType] = useState([])
    const [labour, setLabour] = useState([])
    const [formData, setFormData] = useState({ otH: "00:00:00", });


    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };
    const onChange = (time, timeString) => {
        setFormData((prev) => ({
            ...prev, otH: timeString

        }))
    };


    const fetchgetLabourWorkType = async (data) => {
        try {
            const res = await getfilterLabourWorktype(data);

            if (res?.data?.data) {
                setLabour(res?.data?.data)
            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }

    const fetchgetWorkType = async (department_id) => {
        try {
            const res = await getWorkType(department_id);

            if (res?.data?.data) {
                setWorkType(res?.data?.data)

            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }


    const fetch = async () => {
        try {
            const res = await getDepartment();
            // toast.success(res?.data?.message)
            console.log(res?.data?.data);
            if (res?.data?.data) {
                setDepart(res?.data?.data)
            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }

    useEffect(() => {
        fetch()
    }, [])


    const handleDepartment = (e) => {
        const { id, value } = e.target;
        const valu = value.split(",");
        const _id = valu[0];
        const name = valu[1];
        console.log();
        setFormData({
            ...formData,
            [id]: name
        });

        fetchgetWorkType(_id)

    }

    const handleWorkType = (e) => {
        const { id, value } = e.target;
        fetchgetLabourWorkType(value)
        setFormData({
            ...formData,
            [id]: value
        });
    }

    const handleLabour = (e) => {
        const { id, value } = e.target;
        const valu = value.split(",");

        const labour_id = valu[0];
        const name = valu[1];

        setFormData({
            ...formData,
            [id]: name, labour_id
        });
    }

    const submit = async () => {
        if (!formData.department || !formData.workType || !formData.labour || !formData.labour_id || !formData.presentOrNot) {
            toast.error('Please fill all fields');
            return;
        }

        try {
            const res = await putAttendence(formData);
            if (res?.data?.message) {
                toast.success(res?.data?.message)
            } else {
                toast.error(res?.response?.data?.message)
            }
            console.log(res);
        } catch (error) {
            toast.error(error);
        }
    }

    function getDate(date) {
        const currentDate = new Date(date);
        const year = currentDate.getFullYear();
        const month = currentDate.toLocaleString('default', { month: 'long' });
        const day = currentDate.getDate();
        return `${day}/${month}/${year}`;
    }


    return (
        <div>

            <div className="card-body">
                <h4 className="card-title mb-4">Attendence Form</h4>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="department">Department Type</label>
                            <select
                                id="department"
                                className="form-control"
                                onChange={handleDepartment}
                            >
                                <option value="">Select Department</option>
                                {department.map((item, index) => (
                                    <option key={index} value={`${item._id},${item.name}`} >{item?.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {worktype.length !== 0 && (
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="workType">Work Type</label>
                                <select
                                    id="workType"
                                    className="form-control"
                                    onChange={handleWorkType}
                                >
                                    <option value="">Select workType</option>
                                    {
                                        worktype.map((item, index) => (
                                            <option value={item?.name} key={index} >{item?.name}</option>
                                        ))

                                    }
                                </select>
                            </div>
                        </div>
                    )}

                    {
                        labour.length !== 0 && (
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="labour">Labour Name </label>

                                    <select
                                        id="labour"
                                        className="form-control"
                                        onChange={handleLabour}
                                    > <option value="" defaultValue>Select Labour</option>
                                        {labour.map((item, index) => (
                                            <option key={index} value={`${item._id},${item.name}`}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )
                    }

                    <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="presentOrNot">Present</label>
                            <select name="presentOrNot" id="presentOrNot" className='form-control' onChange={handleInputChange}>
                                <option value="">Select Status</option>
                                <option value="Yes" defaultValue>Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="MonthAndDate">Date </label>  <br />
                            <input
                                type="date"
                                className="form-control"
                                id="MonthAndDate"
                                onChange={(e) => {
                                    setFormData(
                                        {
                                            ...formData,
                                            MonthAndDate: getDate(e.target.value)
                                        }
                                    )
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="otH">OT in hr </label>  <br />
                            <TimePicker id='otH' name='otH' onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />
                        </div>
                    </div>



                </div>
            </div>

            <div>
                <button onClick={submit} className="btn btn-primary w-md">Submit</button>
            </div>

        </div >
    )
}

export default LabourAttendence