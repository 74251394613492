import React, { useEffect, useState } from 'react';
import { getfilterLabour, deleteLabour, updateLabour, getDepartment, getWorkType } from '../../ContextApi/Api/api';
import toast from 'react-hot-toast';
import { Modal, Button } from 'antd'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import jsPDF from 'jspdf'

const ShowLabour = () => {
  const [data, setData] = useState([]);

  const [lastpage, setLastPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);

  const [filtDepart, setFiltDepart] = useState('');
  const [filWorkType, setFilWorkType] = useState('');

  const fetch = async () => {
    try {

      const res = await getfilterLabour({ name: { department: filtDepart, workType: filWorkType }, page: currentPage });
      if (res?.data?.data) {
        setData(res?.data?.data);
        setCurrentPage(res?.data?.current_page)
        setLastPage(res?.data?.last_page)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!');
    }
  };


  const [updt, setUpdate] = useState(false);

  const handelDelete = async (data) => {
    try {
      const res = await deleteLabour(data);
      if (res?.data?.message) {
        toast.success(res?.data?.message);
        fetch()
      } else {
        toast.error(res?.response?.data?.message)
      }

    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  }

  const [formData, setFormData] = useState({});
  const [department, setDepart] = useState([])
  const [worktype, setWorkType] = useState([])

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const fetchgetWorkType = async (department_id) => {
    try {
      const res = await getWorkType(department_id);
      // toast.success(res?.data?.message)
      // console.log(res);
      if (res?.data?.data) {
        setWorkType(res?.data?.data)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }

  const fetchDepart = async () => {
    try {
      const res = await getDepartment();
      // toast.success(res?.data?.message)
      // console.log(res?.data?.data);
      if (res?.data?.data) {
        setDepart(res?.data?.data)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }

  const handleDepartment = (e) => {
    const { id, value } = e.target;
    // console.log(e.target);

    fetchgetWorkType(value)
    setFormData({
      ...formData,
      [id]: value
    });

  }

  const handleCancel = () => {
    setUpdate(!updt)
  }

  const handleOK = async () => {
    try {

      const res = await updateLabour({ ...formData })
      // console.log(res);
      if (res?.data?.data) {
        toast.success(res?.data?.message)
        setUpdate(!updt)
        fetch()
      } else {
        toast.error(res?.response?.data?.message)
      }

    } catch (error) {
      toast.error(error);
      console.log(error);
    }

  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const generateInvoicePDF = (item) => {
    const doc = new jsPDF();

    // Add invoice details
    doc.text(`Labour Name: ${item?.name}`, 10, 10);
    doc.text(`Joining Month: ${item?.joinMonth}`, 10, 20);
    doc.text(`Joining Date: ${item?.formattedJoinDate}`, 10, 30);
    doc.text(`Department: ${item?.department}`, 10, 40);
    doc.text(`WorkType: ${item?.workType}`, 10, 70);
    doc.text(`Rate: ${item?.rate}`, 10, 60);

    // Save the PDF
    doc.save(`Invoice_${item.name}.pdf`);
  };


  const filter = (e) => {
    const val = e.target.value.split(',')
    setFilWorkType('')
    setFiltDepart(val[1])

    fetchgetWorkType(val[0])
  }

  useEffect(()=>{
    fetchDepart()
  },[])

  useEffect(() => {
    fetch();
  }, [filtDepart, filWorkType, currentPage]);


  return (
    <div>
      <Modal title="Update Department" open={updt} onOk={handleOK} onCancel={handleCancel}>

        <form >
          <div className="row">

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="name">Labour Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>


            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="joinDate">Join Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="joinDate"
                  value={formData.joinDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>

          </div>

          <div className="row">

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="department">Department Type</label>
                <select
                  id="department"
                  className="form-control"
                  value={formData.department}
                  onChange={handleDepartment}
                > <option value="">Select Department</option>
                  {department.map((item, index) => (
                    <option key={index} value={item._id}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>

            {formData?.department && (
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="workType">Work Type</label>
                  <select
                    id="workType"
                    className="form-control"
                    value={formData.workType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select workType</option>
                    {
                      worktype.map((item, index) => (
                        <option value={item?.name} key={index}>{item?.name}</option>
                      ))

                    }
                  </select>
                </div>
              </div>
            )

            }
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="rate">RATE</label>
                <input
                  type="text"
                  className="form-control"
                  id="rate"
                  value={formData.rate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

        </form>
      </Modal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="col-sm-2">
              <div className="search-box mr-2 mb-2 d-inline-block">
                <div className="position-relative" style={{ display: 'flex', gap: '10px' }}>
                  {/* <input type="text" className="form-control"  placeholder="Department" /> */}

                  <select name="department_id" id="department_id" className='form-control' style={{ minWidth: '200px' }} value={filtDepart} onChange={filter}>
                    <option value='' >Search departement Wise</option>
                    {
                      department.map((item, index) => (
                        <option key={index} value={`${item?._id},${item?.name}`}>{item?.name}</option>
                      ))
                    }
                  </select>

                  <select
                    style={{ minWidth: '200px' }}
                    id="workType"
                    className="form-control"
                    onChange={e => setFilWorkType(e.target.value)}
                    value={filWorkType}
                  >
                    <option value="">Select workType</option>
                    {
                      worktype.map((item, index) => (
                        <option value={item?.name} key={index}>{item?.name}</option>
                      ))

                    }
                  </select>

                  <Button onClick={() => {
                    setFilWorkType('')
                    setFiltDepart('')
                  }}>
                    All
                  </Button>


                </div>
              </div>
            </div>
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Show Labour Details</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><a href="">History</a></li>
                  <li className="breadcrumb-item active">History </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th>Labour Name</th>
                    <th>Join Month</th>
                    <th>Join Date</th>
                    <th>Department</th>
                    <th>RATE</th>
                    <th>Work TYPE</th>
                    <th>Generate Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    const joinDate = new Date(item.joinDate);

                    const formattedJoinDate = joinDate.toISOString().split('T')[0];

                    return (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>{item?.joinMonth}</td>
                        <td>{formattedJoinDate}</td>
                        <td >{item?.department}</td>
                        <td>{item?.rate}</td>
                        <td>{item?.workType}</td>
                        <td>
                          <td onClick={() => generateInvoicePDF(item)}>Generate Invoice</td>
                          &nbsp; &nbsp;
                          <i className="fa-regular fa-trash-can hover:text-red-300" onClick={() => handelDelete(item?._id)}></i>
                          &nbsp; &nbsp;
                          <i onClick={() => { setUpdate(!updt); setFormData(item); }} className="fa-solid fa-pen-to-square"></i>

                        </td>
                      </tr>
                    );
                  })}
                </tbody>

              </table>
              <div className='p-2'>
                <Stack spacing={2}>
                  <Pagination count={lastpage} color="secondary" size="small" onChange={handlePageChange} page={currentPage} />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowLabour;
