import { Base_url } from "./url";
import axios from "axios";


// bills 

const createBills = async (data) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/bills`, data, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}


const getBills = async ({ page, data }) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/bills/filter?page=${page}`, data, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const deleteBill = async (id) => {
    try {
        const res = await axios.delete(`${Base_url}/api/v1/bills/${id}`, { withCredentials: true });
        return res;
    } catch (error) {
        return error;
    }
};

const updateBill = async ({ id, data }) => {
    try {
        const res = await axios.put(`${Base_url}/api/v1/bills/${id}`, data, { withCredentials: true });
        return res;
    } catch (error) {
        return error;
    }
};


// 
const createPayment = async (data) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/payments`, data, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const getPayments = async ({ page }) => {
    try {
        const res = await axios.get(`${Base_url}/api/v1/payments?page=${page}`, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

// /api/v1/attendance/getMonthy
const getMonthlyPayment = async (MonthAndDate) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/attendance/getMonthly`, MonthAndDate,
            { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

export {
    createBills,
    getBills,
    deleteBill,
    createPayment,
    getPayments,
    updateBill,
    getMonthlyPayment,
}