// MonthlyPaymentHistory.js

import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { DatePicker } from 'antd'
import { getMonthlyPayment } from '../../ContextApi/Api/bills_api'
import toast from 'react-hot-toast';

function MonthlyPaymentHistory() {
    const [data, setData] = useState([]);
    const [date, setDate] = useState('');
    const [page, setPage] = useState(1)


    const fetchApi = async () => {
        try {
            const res = await getMonthlyPayment({ MonthAndDate: date, page });
            console.log(res?.data?.data);

            if (res?.data?.data) {
                setData(res?.data?.data)
            } else {
                toast.error(res?.response?.data?.message)
            }

        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }

    function changeFormat(date) {
        const inputDate = new Date(date);
        const month = inputDate.toLocaleString('en-US', { month: 'long' });
        const year = inputDate.getFullYear();
        const formattedDate = `${month}/${year}`;
        return formattedDate
    }

    const onChange = (date, dateString) => {
        setDate(changeFormat(dateString))
    };

    useEffect(() => {
        fetchApi()
    }, [date, page])

    return (
        <div className="container-fluid">
            {/* start page title */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">Show Monthly Payment Details</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="''">History</a></li>
                                <li className="breadcrumb-item active">History </li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {/* end page title */}
            <div>
                <DatePicker onChange={onChange} picker="month" />
            </div>

            <div className="row">
                <div className="col-12">

                    {data.length !== 0 ? (
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap">
                                <thead>
                                    <tr>
                                        <th>Department</th>
                                        <th>Month</th>
                                        <th>Join Date</th>
                                        <th>Total Attendance</th>
                                        <th>Overtime Total</th>
                                        <th>Total Amount</th>
                                        <th>PF Amount</th>
                                        <th>ESI Amount</th>
                                        <th>Total Amount After PF ESI</th>
                                        <th>Advance Amount</th>
                                        <th>Final Amount</th>
                                    </tr>
                                </thead>
                                {/* Your table body goes here */}
                                <tbody>

                                    {

                                        data.map((item, index) => {
                                            const joinDate = new Date(item?.JoinDate);

                                            const formattedJoinDate = joinDate.toISOString().split('T')[0];

                                            return <tr key={index}>
                                                <td >{item?.department}</td>
                                                <td className='text-center text-[11px]'>{item?.MonthAndDate}</td>
                                                <td className='text-center text-[11px]'>{formattedJoinDate}</td>
                                                <td className='text-center text-[11px]'>{item?.Attendance}</td>
                                                <td className='text-center text-[11px]'>{item?.totalOTH}</td>
                                                <td className='text-center text-[11px]'>{Math.round(item?.totalAmountAfterPFESI + item?.pfAmount + item?.esiAmount, 1)}</td>
                                                <td className='text-center text-[11px]'>{item?.pfAmount}</td>
                                                <td className='text-center text-[11px]'>{item?.esiAmount}</td>
                                                <td className='text-center text-[11px]'>{item?.totalAmountAfterPFESI}</td>
                                                <td className='text-center text-[11px]'>{item?.AdvanceAmount}</td>
                                                <td className='text-center text-[11px]'>{item?.totalAmountAfterPFESI}</td>

                                            </tr>
                                        })


                                    }
                                </tbody>


                            </table>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', fontSize: '40px', color: 'gray' }}> Data Not Found </div>
                    )
                    }


                </div>
            </div>
            {/* end row */}
        </div >
    );
}

export default MonthlyPaymentHistory;
