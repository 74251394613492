import React, { useState } from "react";
import { Col, Input, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import IMAGES from "../const/Image";
import LeftSection from "./LeftSection";
import { useFormContext } from '../../ContextApi/FormContext'

const Login = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const {Login} = useFormContext()
  const navigate =  useNavigate()

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleLogin =async () => {
    const res =await Login(values)
    if (res) {
      navigate('/')
    }
  };

  return (
    <div className="loginPage">
      <Row gutter={[0, 16]}>
        <Col
          md={15}
          sm={24}
          xs={24}
          className="flex justify-content-center align-items-center mh-100"
        >
          <div className="loginFormbox">
            <div className="flex justify-content-center">
              <img src={IMAGES.LogoHeader} alt="" className="scale_img mb-30" />
            </div>
           
            <p className="fw-600 fs-16">Sign in to your account</p>
            <div className="loginForm">
              <div className="form-group">
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control ant-email"
                    autoComplete="new-off"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    value={values.email || ""}
                  />
                </div>
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="form-group">
                <div className="input-group">
                  <Input.Password
                    type={show ? "text" : "password"}
                    className="form-control"
                    autoComplete="new-off"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    value={values.password || ""}
                  />
                </div>
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
            
              <button
                type="button"
                className="gradient-button w-100"
                onClick={handleLogin}
              >
                Sign In
              </button>
            </div>
          </div>
        </Col>
        <Col md={9} sm={24} xs={24} className="mh-100">
          <LeftSection />
        </Col>
      </Row>
    </div>
  );
};

export default Login;
