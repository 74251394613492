import React, { useState, useEffect } from 'react';
import { createLabour, getDepartment, getWorkType } from '../../ContextApi/Api/api';
import toast from 'react-hot-toast';

const RegistrationLabor = () => {
  // State to hold form data
  const [formData, setFormData] = useState({
    name: "",
    joinDate: '',
    rate: "",
    department: "",
    workType: "",
    description: ""
  });

  const [department, setDepart] = useState([])
  const [worktype, setWorkType] = useState([])


  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.name.trim() === "" ||
      formData.joinDate.trim() === "" ||
      formData.rate.trim() === "" ||
      formData.department.trim() === "" ||
      formData.workType.trim() === "" ||
      formData.description.trim() === ""
    ) {
      toast.error("Please fill in all fields");
      return;
    }


    try {
      const res = await createLabour(formData);
      if (res?.data?.success) {
        toast.success(res?.data?.message)
        setFormData({
          name: "",
          joinDate: '',
          rate: "",
          department: "",
          workType: "",
          description: ""
        })
      }

    } catch (error) {
      console.log(error);
      toast.error('Try again !')
    }

  };

  const fetchgetWorkType = async (department_id) => {
    try {
      const res = await getWorkType(department_id);
      // toast.success(res?.data?.message)
      if (res?.data?.data) {
        setWorkType(res?.data?.data)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }

  const fetch = async () => {
    try {
      const res = await getDepartment();
      // toast.success(res?.data?.message)
      // console.log(res?.data?.data);
      if (res?.data?.data) {
        setDepart(res?.data?.data)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }


  useEffect(() => {
    fetch()
  }, [])


  const handleDepartment = (e) => {
    const { id, value } = e.target;
    const val = value.split(',')
  

    fetchgetWorkType(val[0])
    setFormData({
      ...formData,
      [id]: value
    });

  }

  return (
    <div>
      <div className="card-body">
        <h4 className="card-title mb-4">Form Fill</h4>
        <form >


          <div className="row">

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="department">Department Type</label>
                <select
                  id="department"
                  className="form-control"
                
                  onChange={handleDepartment}
                > <option value="">Select Department</option>
                  {department.map((item, index) => (
                    <option key={index} value={`${item._id},${item.name}`}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>

            {worktype.length !== 0 && (
              <div className="col-lg-4">
                <div className="form-group">
                  <label htmlFor="workType">Work Type</label>
                  <select
                    id="workType"
                    className="form-control"
                    value={formData.workType}
                    onChange={handleInputChange}
                  >
                    <option value="">Select workType</option>
                    {
                      worktype.map((item, index) => (
                        <option value={item?.name} key={index}>{item?.name}</option>
                      ))

                    }
                  </select>
                </div>
              </div>
            )

            }
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="rate">RATE</label>
                <input
                  type="text"
                  className="form-control"
                  id="rate"
                  value={formData.rate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="name">Labour Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="joinDate">Join Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="joinDate"
                  value={formData.joinDate}
                  onChange={handleInputChange}
                />
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div>
            <button onClick={handleSubmit} className="btn btn-primary w-md">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationLabor;
