import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { logout} from '../../../ContextApi/Api/api'

const TopBar = ({ collapsed, setCollapsed }) => {
    const navigate = useNavigate();
    const handleLogout = async () => {
        const res = await logout();
        if(res){
            navigate("/login", { replace: true });
        }
    }
    return (
        <div className='flex justify-content-between align-items-center'>
            <div>{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
            })}</div>
            <div className='mr-20'>
                <span className='logout-btn' onClick={() => handleLogout()}>Logout<LogoutOutlined /></span>
            </div>
        </div >
    )
}

export default TopBar