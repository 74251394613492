// ShowBills.js

import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { getDepartment } from '../../ContextApi/Api/api';
import { getBills, deleteBill, updateBill } from '../../ContextApi/Api/bills_api'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../ContextApi/FormContext'
import { Modal } from 'antd';
import parse from 'html-react-parser';
import { DatePicker } from 'antd'


function ShowBills() {
    const { setBill } = useFormContext()
    const [data, setData] = useState([])
    const [lastpage, setLastPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()

    const [department, setDepart] = useState('')
    const [po, setPo] = useState('')
    const [updt, setUpdate] = useState(false);
    const [updateData, setUpdateData] = useState({})
    const [departmentUp, setDepartUp] = useState([])
    const [month, setmonth] = useState('');

    const fetchDepartment = async () => {
        try {
            const res = await getDepartment();
            // toast.success(res?.data?.message)
            console.log(res?.data?.data);
            if (res?.data?.data) {
                setDepartUp(res?.data?.data)
            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }
    const fetch = async () => {
        try {
            const res = await getBills({ page: currentPage, data: { departmentType: department, poNumber: po, month } });
            // toast.success(res?.data?.message)
            // console.log(res);
            if (res?.data?.data) {
                setCurrentPage(res?.data?.current_page)
                setLastPage(res?.data?.last_page)
                setData(res?.data?.data)
            } else {
                toast.error(res?.response?.data?.message)
            }
        } catch (error) {
            console.log(error);
            toast.error('Network error!')
        }
    }


    const generateInvoicePDF = (bill) => {
        setBill(bill)
        navigate('/pdf')
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    const handelDelete = async (id) => {
        try {
            const res = await deleteBill(id);
            if (res?.data?.message) {
                toast.success(res?.data?.message);
                fetch()
            } else {
                toast.error(res?.response?.data?.message)
            }
        } catch (error) {
            toast.error(error);
            console.log(error);
        }
    }

    const handleCancel = () => {
        setUpdate(!updt)
    }


    const handleOK = async () => {
        try {

            const res = await updateBill({ data: updateData, id: updateData?._id })
            console.log(res);
            if (res?.data?.data) {
                toast.success(res?.data?.message)
                setUpdate(!updt)
                setUpdateData({})
                fetch()
            } else {
                toast.error(res?.response?.data?.message)
            }

        } catch (error) {
            toast.error(error);
            console.log(error);
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdateData({ ...updateData, [name]: value });
    };


    const onChange = (date, dateString) => {
        setmonth(dateString);
    };


    useEffect(() => {
        fetch()
    }, [currentPage, po, department, month])

    return (
        <div className="container-fluid">
            <Modal title={`Update Bill ${updateData.poNumber}`} open={updt} onCancel={handleCancel} onOk={handleOK}>


                <div className="row">
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label htmlFor="invoiceNumber">INVOICE </label>
                            <input type="text" className="form-control" id="invoiceNumber" name="invoiceNumber" value={updateData.invoiceNumber} onChange={handleChange} />
                        </div>
                    </div> <br />
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label htmlFor="poNumber">PO NUMBER</label>
                            <input type="text" className="form-control" id="poNumber" name="poNumber" value={updateData.poNumber} onChange={handleChange} />
                        </div>
                    </div>


                    <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="departmentType">Department </label>
                            <select className="form-control" id="departmentType" name="departmentType" value={updateData.departmentType} onChange={handleChange}>
                                <option value="">Select Department</option>
                                {departmentUp.map((item, index) => (
                                    <option key={index} value={`${item._id},${item.name}`} >{item?.name}</option>
                                ))}
                            </select>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label htmlFor="startDate">START DATE</label>
                            {/* Parse the date using Moment.js, then format it */}
                            <input type="date" className="form-control" id="startDate" name="startDate" value={moment(updateData.startDate).format('YYYY-MM-DD')} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label htmlFor="endDate">END DATE</label>
                            {/* Parse the date using Moment.js, then format it */}
                            <input type="date" className="form-control" id="endDate" name="endDate" value={moment(updateData.endDate).format('YYYY-MM-DD')} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="billDate">BILL DATE</label>
                            {/* Parse the date using Moment.js, then format it */}
                            <input type="date" className="form-control" id="billDate" name="billDate" value={moment(updateData.billDate).format('YYYY-MM-DD')} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="form-group">
                            <label htmlFor="date">Date</label>
                            {/* Parse the date using Moment.js, then format it */}
                            <input type="date" className="form-control" id="date" name="date" value={moment(updateData.date).format('YYYY-MM-DD')} onChange={handleChange} />
                        </div>
                    </div>
                </div>


                <div className="row">

                    <div className="col-lg-3">
                        <div className="form-group">
                            <label htmlFor="unit">UNIT</label>
                            <input type="text" className="form-control" id="unit" name="unit" value={updateData.unit} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label htmlFor="quantity">Quantity</label>
                            <input type="number" className="form-control" id="quantity" name="quantity" value={updateData.quantity} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-group">
                            <label htmlFor="rate">RATE</label>
                            <input type="number" className="form-control" id="rate" name="rate" value={updateData.rate} onChange={handleChange} />
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-group">
                            <label htmlFor="poDescription">PO Description</label>
                            <input type="text" className="form-control" id="poDescription" name="poDescription" value={updateData.poDescription} onChange={handleChange} />
                        </div>
                    </div>
                </div>


            </Modal>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">Show Bill</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="''">History</a></li>
                                <li className="breadcrumb-item active">History </li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-2">



                                <div className="col-sm-2">
                                    <div className="search-box mr-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                            <input type="text" className="form-control" onChange={e => setDepart(e.target.value)} placeholder="Department wise" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-2">
                                    <div className="search-box mr-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                            <input type="text" onChange={e => setPo(e.target.value)} className="form-control" placeholder="Po Number" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-2">
                                    <DatePicker onChange={onChange} picker="month" />
                                </div>


                            </div>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap">
                                    <thead>
                                        <tr>
                                            <th>INVOICE NUMBER</th>
                                            <th>PO NUMBER</th>
                                            <th>DEPARTMENT</th>
                                            <th>JOB START DATE</th>
                                            <th>JOB END DATE</th>
                                            <th>QUANTITY</th>
                                            <th>RATE</th>
                                            <th>UNIT</th>
                                            <th>AMOUNT</th>
                                            <th>Generate Invoice</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.invoiceNumber}</td>
                                                <td>{item.poNumber}</td>
                                                <td>{item.departmentType.split(',')[1]}</td>
                                                <td>{new Date(item.startDate).toLocaleDateString('en-GB')}</td>
                                                <td>{new Date(item.endDate).toLocaleDateString('en-GB')}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.rate}</td>
                                                <td>{item.unit}</td>
                                                <td>{item.rate * item.quantity}</td>
                                                <td >
                                                    <div style={{ background: 'blue', color: 'white', textAlign: 'center' }} onClick={() => generateInvoicePDF(item)}>
                                                        Generate Invoice
                                                    </div>

                                                    &nbsp; &nbsp;
                                                    <i className="fa-regular fa-trash-can hover:text-red-300" onClick={() => handelDelete(item?._id)}></i>

                                                    &nbsp; &nbsp;   &nbsp; &nbsp;
                                                    <i onClick={() => { fetchDepartment(); setUpdate(!updt); setUpdateData(item) }} className="fa-solid fa-pen-to-square"></i>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                                <div className='p-2'>
                                    <Stack spacing={2}>
                                        <Pagination count={lastpage} color="secondary" size="small" onChange={handlePageChange} page={currentPage} />
                                    </Stack>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {/* end row */}
        </div>
    );
}

export default ShowBills;
