import React, { useEffect, useState } from 'react'
import { getAllWorkType, deleteWorkType, updateWorktype } from '../../ContextApi/Api/api'
import toast from 'react-hot-toast';
import { Modal, Button } from 'antd'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


function ShowDepartmentRate() {
  const [lastpage, setLastPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([])
  const [name, setName] = useState('')

  const [updt, setUpdate] = useState(false);
  const [formData, setFormData] = useState({});

  const fetch = async () => {
    try {
      const res = await getAllWorkType({ name, page: 1 });
      if (res?.data?.data) {
        setData(res?.data?.data)
        setCurrentPage(res?.data?.current_page)
        setLastPage(res?.data?.last_page)
      } else {
        toast.error(res?.response?.data?.message)
      }
    } catch (error) {
      console.log(error);
      toast.error('Network error!')
    }
  }


  const handelDelete = async (id) => {
    try {
      const res = await deleteWorkType(id);
      if (res?.data?.message) {
        toast.success(res?.data?.message);
        fetch()
      } else {
        toast.error(res?.response?.data?.message)
      }

    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  }


  const handleCancel = () => {
    setUpdate(!updt)
  }

  const handleOK = async () => {
    try {

      const res = await updateWorktype(formData)
      console.log(res);
      if (res?.data?.data) {
        toast.success(res?.data?.message)
        setUpdate(!updt)
        fetch()
      } else {
        toast.error(res?.response?.data?.message)
      }

    } catch (error) {
      toast.error(error);
      console.log(error);
    }

  }

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  useEffect(() => {
    fetch()
  }, [name, currentPage])






  return (
    <div>
      <Modal title="Update WorkType" open={updt} onOk={handleOK} onCancel={handleCancel}>
        {/* name, owner, mobile */}
        <form>

          <div className="row">

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="department_id">Department Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData?.department_id?.name}
                  required
                  readOnly
                />

              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="departmentname">Work Type</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="rateNumber">Rate</label>
                <input
                  type="text"
                  className="form-control"
                  id="rate"
                  value={formData.rate}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>



      <div className="card-body">
        <div className="row mb-2">
          <div className="col-sm-2">
            <div className="search-box mr-2 mb-2 d-inline-block">
              <div className="position-relative">
                <input type="text" className="form-control " onChange={e => setName(e.target.value)} placeholder="WorkType" />

               
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>WorkType Name</th>
                  <th>Department Name</th>
                  <th>WorkType Rate</th>
                </tr>
              </thead>
              <tbody>

                {
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item?.department_id?.name}</td>
                      <td>{item.rate}  &nbsp;  &nbsp;
                        <i onClick={() => handelDelete(item?._id)} className="fa-regular fa-trash-can hover:text-red-300"></i>
                        &nbsp; &nbsp;
                        <i onClick={() => { setUpdate(!updt); setFormData(item) }} className="fa-solid fa-pen-to-square"></i>
                      </td>
                    </tr>
                  ))
                }

              </tbody>
            </table>
            <div className='p-2'>
              <Stack spacing={2}>
                <Pagination count={lastpage} color="secondary" size="small" onChange={handlePageChange} page={currentPage} />
              </Stack>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}

export default ShowDepartmentRate