import { Base_url } from "./url";
import axios from "axios";

const login = async (data) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/user/login`, data, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })

        return res
    } catch (error) {
        if (error.response) {

            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {

            console.log(error.request);
        } else {

            console.log('Error', error.message);
        }
        console.log(error.config);
        return error
    }
}


const authCheck = async () => {
    try {
        const res = await axios.get(`${Base_url}/api/v1/user/islogin`, { withCredentials: true })
        if (res?.data?.success) {
            return true
        }
        return false
    } catch (error) {
        return false
    }
}

const logout = async () => {
    try {
        const res = await axios.get(`${Base_url}/api/v1/user/logout`, { withCredentials: true })
        if (res?.data?.success) {
            return true
        }
        return false
    } catch (error) {
        return false
    }
}

const createDepartment = async (data) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/department`, data, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const getfilterDepartment = async ({ name, page }) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/department/getMobileDepartments?page=${page}`, { name }, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const updateDepartment = async (data) => {
    try {
        const res = await axios.put(`${Base_url}/api/v1/department`, data, { withCredentials: true })

        return res
    } catch (error) {
        return error
    }
}

const deleteDepartment = async (data) => {
    try {
        const res = await axios.delete(`${Base_url}/api/v1/department`, {
            data: { id: data },
            withCredentials: true
        })
        return res
    } catch (error) {
        return error
    }
}


const getfilterDepartmentRate = async ({ name, page }) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/department/getRateDepartments?page=${page}`, { name }, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const getDepartment = async () => {
    try {
        const res = await axios.get(`${Base_url}/api/v1/department`, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}


const createWorkType = async (data) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/worktype`, data, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const getWorkType = async (department_id) => {
    try {
        if (!department_id) return
        const res = await axios.get(`${Base_url}/api/v1/worktype?department_id=${department_id}`, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}


const getAllWorkType = async ({ name, page }) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/worktype/getAllWorkType?page=${page}`, { name }, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const deleteWorkType = async (id) => {
    try {
        if (!id) return
        const res = await axios.delete(`${Base_url}/api/v1/worktype`, {
            data: { id },
            withCredentials: true
        })
        return res
    } catch (error) {
        return error
    }
}

// updateWorktype

const updateWorktype = async (form) => {
    try {
        const res = await axios.put(`${Base_url}/api/v1/worktype`, form, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}


// filter
const createLabour = async (data) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/labour`, data, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const getfilterLabour = async ({name, page}) => {
    try {

        const res = await axios.post(`${Base_url}/api/v1/labour/filter?page=${page}`, name , { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const getfilterLabourWorktype = async (name) => {
    try {

        const res = await axios.get(`${Base_url}/api/v1/labour/filter?workType=${name}`, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}


const deleteLabour = async (id) => {
    try {
        if (!id) return
        const res = await axios.delete(`${Base_url}/api/v1/labour`, {
            data: { id },
            withCredentials: true
        })
        return res
    } catch (error) {
        return error
    }
}

const updateLabour = async (data) => {
    try {
        const res = await axios.put(`${Base_url}/api/v1/labour`, data, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

// /api/v1/attendance

const putAttendence = async (data) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/attendance`, data, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

const getAttendence = async ({ page, data }) => {
    try {
        const res = await axios.post(`${Base_url}/api/v1/attendance/filter?page=${page}`, data ,{ withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}

// getLabourHistory

const getLabourHistory = async ({ page, id }) => {
    try {
        const res = await axios.get(`${Base_url}/api/v1/attendance/getLabourHistory?page=${page}&id=${id}`, { withCredentials: true })
        return res
    } catch (error) {
        return error
    }
}



export {
    login,
    authCheck,
    logout,
    createDepartment,
    getfilterDepartment,
    getfilterDepartmentRate,
    updateDepartment,
    getfilterLabour,
    createLabour,
    getDepartment,
    createWorkType,
    getAllWorkType,
    getWorkType,
    deleteDepartment,
    deleteWorkType,
    deleteLabour,
    updateLabour,
    updateWorktype,
    getfilterLabourWorktype,
    putAttendence,
    getAttendence,
    getLabourHistory
}