import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Home from "../Component/Home/Home";
import Login from "../Component/Login/Login";
import LayoutMain from "../Component/Layout";
import ShowDepartment from '../Component/Department/ShowDepartment';
import CreateDepartment from '../Component/Department/CreateDepartment';
import ShowLabour from '../Component/Labour/ShowLabour';
import RegistrationLabor from '../Component/Labour/RegistrationLabor';
import ShowDepartmentRate from '../Component/Department/ShowDepartmentRate';
import CreateDepartmentRate from '../Component/Department/CreateDepartmentRate';
import LabourAttendence from '../Component/Attendence/LabourAttendence';
import ShowLabourAttendence from '../Component/Attendence/ShowLabourAttendence'
import CreateBills from '../Component/BillManagement/CreateBills'
import ShowBills from '../Component/BillManagement/ShowBills'
import MonthlyPaymentHistory from '../Component/MonthlyPaymentList/MonthlyPaymentHistory'
import PaymentHistory from '../Component/PaymentManagement/PaymentHistory'
import ShowPaymentHistory from '../Component/PaymentManagement/ShowPaymentHistory'
import CreatePfESi from '../Component/PfESIManagement/CreatePfESi'
import ShowPfESiHistory from '../Component/PfESIManagement/ShowPfESiHistory'
import GenerateBill from "../Component/BillManagement/GenerateBill";
import { authCheck, logout } from '../ContextApi/Api/api'

import { useEffect } from "react";

function PageRouter() {
  const navigate = useNavigate()

  const fetch = async () => {
    const res = await authCheck();
    if (!res) {
      navigate('/login')
    }

  }
  useEffect(() => {
    fetch()

  }, [])

  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<LayoutMain />}>
          <Route path="/" element={<Home />} />

          <Route path="/createdepartment" element={<CreateDepartment />} />
          <Route path="/ShowDepartment" element={<ShowDepartment />} />

          <Route path="/createdepartmentrate" element={<CreateDepartmentRate />} />
          <Route path="/ShowDepartmentrate" element={<ShowDepartmentRate />} />

          <Route path="/labourregistration" element={<RegistrationLabor />} />
          <Route path="/showlabour" element={<ShowLabour />} />

          <Route path="/labourAttendence" element={<LabourAttendence />} />
          <Route path="/showLabourAttendence" element={<ShowLabourAttendence />} />

          <Route path="/createbill" element={<CreateBills />} />
          <Route path="/showbills" element={<ShowBills />} />

          <Route path="/monthly-pmt-hst" element={<MonthlyPaymentHistory />} />

          <Route path="/PaymentHistory" element={<PaymentHistory />} />
          <Route path="/ShowPaymentHistory" element={<ShowPaymentHistory />} />


          <Route path="/CreatePfESi" element={<CreatePfESi />} />
          <Route path="/ShowPfESiHistory" element={<ShowPfESiHistory />} />


          <Route path="/pdf" element={<GenerateBill />} />


        </Route>
      </Routes>
    </div>
  );
}

export default PageRouter;
