import React from 'react'
import { useNavigate } from 'react-router-dom'
import IMAGES from '../../const/Image'

const Logo = ({ collapsed }) => {
    const navigate = useNavigate();
    return (
        <div className={collapsed ? "logo shortlogo" : "logo longlogo"} style={{background:"#2A3042"}}>
            {collapsed ?
                < img src={IMAGES.LogoShort}  className="cursor-pointer" alt="" onClick={() => navigate("/")} />
                :
                <img src={IMAGES.LogoHeader} title='fghg' className="cursor-pointer" alt="" onClick={() => navigate("/")} />
            }
        </div>
    )
}

export default Logo